import React from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import ContactForm from '../../components/ui/Forms/ContactForn/ContactForm';
import Seo from '../../components/utils/Seo';
import { Direction } from '../../globals';
import './contact.scss';

export default function Contact() {
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Contact"
                url="contact/"
                description="Contact us if you want to collaborate or you need help with Deep Learning"
            />
            <PageSection>
                <Container>
                    <Group size={2}>
                        <Block direction={Direction.vertical}>
                            <h1>Contact Us</h1>
                            <h4>
                                If you want to collaborate or contribute to AI Summer, feel free to drop us
                                a message. We can be reached through email,
                                the form below, or on social. We also maintain a{' '}
                                <a
                                    className="contact-us__link"
                                    href="https://discord.gg/2ezWq3r5hv"
                                >
                                    Discord server
                                </a>
                                , which is the best way to make sure that your
                                message will get to us.
                            </h4>
                            <ContactForm />
                        </Block>
                        <Block direction={Direction.vertical}>
                            <h2>Stay connected</h2>
                            <Block
                                direction={Direction.horizontal}
                                className="follow-us"
                            >
                                <a
                                    href="https://github.com/The-AI-Summer"
                                    className="follow-us__item__social"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="48"
                                        height="48"
                                        fill="#333"
                                        viewBox="0 0 24 24"
                                    >
                                        {/* eslint-disable-next-line max-len */}
                                        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                                    </svg>
                                </a>
                                <a
                                    href="https://www.twitter.com/theaisummer"
                                    className="follow-us__item__social"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="48" height="48">
                                        <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"/>
                                    </svg>
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/ai-summer/"
                                    className="follow-us__item__social"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="48"
                                        height="48"
                                        fill="#007bb5"
                                        viewBox="0 0 24 24"
                                    >
                                        {/* eslint-disable-next-line max-len */}
                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                                    </svg>
                                </a>
                                <a
                                    href="https://www.facebook.com/AI-Summer-104358791110338"
                                    className="follow-us__item__social"
                                >
                                    <svg
                                        viewBox="0 0 128 128"
                                        width="48"
                                        height="48"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            // eslint-disable-next-line max-len
                                            d="M128,112c0,8.8-7.2,16-16,16H16c-8.8,0-16-7.2-16-16V16C0,7.2,7.2,0,16,0h96c8.8,0,16,7.2,16,16V112z"
                                            fill="#3C579E"
                                        />
                                        <path
                                            // eslint-disable-next-line max-len
                                            d="M68.877,128V78.188h-17.78V60.425h17.784V44.029c0-16.537,9.764-26.279,24.514-26.279  c7.068,0,12.834,0.756,14.605,0.991v18.573l-11.874-0.005c-8.022,0-9.523,3.979-9.523,9.572v13.544h20.556l-2.904,17.763H86.603V128  H68.877z"
                                            fill="#FFFFFF"
                                        />
                                    </svg>
                                </a>
                                <a
                                    href="https://discord.gg/2ezWq3r5hv"
                                    className="follow-us__item__social--discord"
                                >
                                    <svg
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 245 240"
                                        width="64"
                                        height="64"
                                    >
                                        {/* eslint-disable-next-line max-len*/}
                                        <path d="M104.4 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1.1-6.1-4.5-11.1-10.2-11.1zM140.9 103.9c-5.7 0-10.2 5-10.2 11.1s4.6 11.1 10.2 11.1c5.7 0 10.2-5 10.2-11.1s-4.5-11.1-10.2-11.1z" />
                                        <path
                                            fill="#7289DA"
                                            // eslint-disable-next-line max-len
                                            d="M189.5 20h-134C44.2 20 35 29.2 35 40.6v135.2c0 11.4 9.2 20.6 20.5 20.6h113.4l-5.3-18.5 12.8 11.9 12.1 11.2 21.5 19V40.6c0-11.4-9.2-20.6-20.5-20.6zm-38.6 130.6s-3.6-4.3-6.6-8.1c13.1-3.7 18.1-11.9 18.1-11.9-4.1 2.7-8 4.6-11.5 5.9-5 2.1-9.8 3.5-14.5 4.3-9.6 1.8-18.4 1.3-25.9-.1-5.7-1.1-10.6-2.7-14.7-4.3-2.3-.9-4.8-2-7.3-3.4-.3-.2-.6-.3-.9-.5-.2-.1-.3-.2-.4-.3-1.8-1-2.8-1.7-2.8-1.7s4.8 8 17.5 11.8c-3 3.8-6.7 8.3-6.7 8.3-22.1-.7-30.5-15.2-30.5-15.2 0-32.2 14.4-58.3 14.4-58.3 14.4-10.8 28.1-10.5 28.1-10.5l1 1.2c-18 5.2-26.3 13.1-26.3 13.1s2.2-1.2 5.9-2.9c10.7-4.7 19.2-6 22.7-6.3.6-.1 1.1-.2 1.7-.2 6.1-.8 13-1 20.2-.2 9.5 1.1 19.7 3.9 30.1 9.6 0 0-7.9-7.5-24.9-12.7l1.4-1.6s13.7-.3 28.1 10.5c0 0 14.4 26.1 14.4 58.3 0 0-8.5 14.5-30.6 15.2z"
                                        />
                                    </svg>
                                </a>
                            </Block>
                        </Block>
                    </Group>
                </Container>
            </PageSection>
        </PageLayout>
    );
}
