import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './ContactForm.scss';

interface ContactFormData {
    email: string;
    firstname: string;
    message: string;
}

//TODO:add typing
const ContactForm: React.FC = () => {
    const { register, handleSubmit, errors } = useForm();
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(
        false
    );

    const portalId = 6702664;
    const formId = 'f1615774-c11c-4428-8b79-5d2134844b90';
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const onSubmit = (data: ContactFormData) => {
        sendForm(buildPostBody(data));
    };

    const buildPostBody = (data: ContactFormData) => {

        const isBrowser = typeof window !== 'undefined';
        //const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
        const pageUri = isBrowser ? window.location.href : null;
        const pageName = isBrowser ? document.title : null;

        return {
            submittedAt: Date.now(),
            fields: [
                {
                    name: 'email',
                    value: data.email
                },
                {
                    name: 'firstname',
                    value: data.firstname
                },
                {
                    name: 'message',
                    value: data.message
                }
            ],
            context: {
                //   hutk: hutk,
                pageUri: pageUri,
                pageName: pageName
            }
            //NOT SURE IF GDPR NEEDED HERE  AS IT IS A CONTACT FORM
            // legalConsentOptions: {
            //     consent: {
            //         // Include this object when GDPR options are enabled
            //         consentToProcess: true,
            //         text:
            //             'I agree to allow AI Summer to store and process my personal data.',
            //         communications: [
            //             {
            //                 value: true,
            //                 subscriptionTypeId: 999,
            //                 text:
            //                     'I agree to receive marketing communications from Example Company.'
            //             }
            //         ]
            //     }
            // }
        };
    };

    const sendForm = (postBody: Record<string, unknown>) => {

        try {
            fetch(postUrl, {
                method: 'post',
                body: JSON.stringify(postBody),
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Accept:
                        'application/json, application/xml, text/plain, text/html, *.*'
                })
            });
            setIsSuccessfullySubmitted(true);
        } catch (error) {
            console.log('Request failed', error);
        }
    };

    return isSuccessfullySubmitted ? (
        <div className="contact-form--success">
            <p>Thank you for contacting AI Summer</p>
        </div>
    ) : (
        <div className="contact-form-wrapper">
            <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
                <label className="contact-form__label">Name</label>
                <input
                    className="contact-form__name"
                    type="text"
                    name="firstname"
                    placeholder="Type your name"
                    ref={register}
                />
                <label className="contact-form__label">Email</label>
                <input
                    className="contact-form__email"
                    type="text"
                    name="email"
                    placeholder="Type your email"
                    ref={register({
                        required: 'Email is required',
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Enter a valid e-mail address'
                        }
                    })}
                />
                {errors.email && (
                    <span className="contact-form__errormsg">
                        {errors.email.message}
                    </span>
                )}
                <label className="contact-form__label">Message</label>

                <textarea
                    className="contact-form__message"
                    name="message"
                    ref={register({
                        required: 'Message is required'
                    })}
                />
                {errors.message && (
                    <span className="contact-form__errormsg">
                        {errors.message.message}
                    </span>
                )}

                <button className="contact-form__submit" type="submit">
                    Send message
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
